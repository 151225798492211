<template>
  <draggable
    v-model="draggableList"
    :disabled="!allows_crud"
    tag="b-tbody"
    handle=".handle"
    ><template v-if="draggableList.length > 0">
      <b-tr v-for="option in draggableList" :key="option.id">
        <b-td
          class="text-center handle"
          :class="{
            grabbable: allows_crud,
            'selected-class':
              (ids_left_position.includes(option.id) && allows_crud) ||
              (tem_ids_left_position.includes(option.id) && preview_evaluation),
          }"
          v-if="allows_crud"
          ><b-icon v-if="allows_crud" icon="arrows-expand" scale="1.2"></b-icon
        ></b-td>
        <td v-if="evaluator_view && user_id && !display_position_left">
          {{ OrdenLeftPosition(option) }}
        </td>
        <td
          :class="{
            'selected-class':
              (ids_left_position.includes(option.id) && allows_crud) ||
              (tem_ids_left_position.includes(option.id) && preview_evaluation),
          }"
        >
          <template v-if="display_position_left">
            {{ String.fromCharCode(96 + option.order).toUpperCase() }}
          </template>
          <template v-else>
            <div class="noprint" v-if="!evaluatee_view">
              <template
                v-if="
                  !preview_evaluation ||
                  (evaluator_view && !display_position_left)
                "
              >
                {{ option.order }}
              </template>
              <template v-else-if="preview_evaluation">
                {{ OrdenTemLeftPosition(option) }}
              </template>
            </div>
            <div v-else-if="evaluatee_view">
              {{ OrdenLeftPosition(option) }}
            </div>
          </template>
        </td>
        <td
          :class="{
            'selected-class':
              (ids_left_position.includes(option.id) && allows_crud) ||
              (tem_ids_left_position.includes(option.id) && preview_evaluation),
          }"
        >
          <div
            v-b-tooltip.v-secondary="`${allows_crud ? 'Editar' : ''}`"
            class="input-text rich-text-content"
            :class="{
              'selected-class-rich-text':
                (ids_left_position.includes(option.id) && allows_crud) ||
                (tem_ids_left_position.includes(option.id) &&
                  preview_evaluation),
            }"
            @click="
              () => {
                if (allows_crud)
                  $bvModal.show(
                    `descriptor-matching-option-${display_position_left}-${option.id}-${container_instrument_id}`
                  );
              }
            "
            v-html="option.title"
          ></div>
        </td>
        <td v-if="allows_crud && !preview_evaluation && display_position_left">
          <b-form-input
            class="ml-2 mr-2 input-number-options"
            type="number"
            min="1"
            v-model="option.how_many_selected"
            @input="PatchScore(option)"
            size="sm"
          ></b-form-input>
        </td>
        <!-- <td
          class="noprint"
          v-if="!display_position_left && !preview_evaluation"
        >
          <b-form-input
            v-if="allows_crud"
            class="mr-2 ml-2 mt-0 input-number"
            :class="{
              'score-input-warning': optionsMaxScore > Question.max_score,
            }"
            type="number"
            min="0"
            v-b-tooltip.top.noninteractive.v-danger="
              `${
                optionsMaxScore > Question.max_score
                  ? 'El puntaje total de las opciones es mayor que el puntaje total de la pregunta'
                  : ''
              }`
            "
            v-model="option.score"
            @input="PatchOptionScore(option)"
            size="sm"
          ></b-form-input>
          <div v-else>
            {{ option.score }}
          </div>
        </td> -->
        <td
          v-if="
            allows_crud ||
            (preview_evaluation && !evaluatee_view && !evaluator_view) ||
            (evaluatee_view && !finished_test && !evaluator_view)
          "
          class="noprint"
        >
          <template v-if="!preview_evaluation">
            <template v-if="!display_position_left">
              <div class="container-buttons-table-right">
                <!-- <b-button
                  v-if="ids_left_position.length > 0"
                  variant="primary"
                  size="sm"
                  class="mt-1 p-1 mr-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Agregar términos al siguiente concepto'
                  "
                  @click="SetIdsButton(option)"
                >
                  <b-icon icon="arrow-left-circle-fill"></b-icon>
                </b-button> -->
                <!-- <b-button
                  v-if="
                    ids_left_position.length == 0 &&
                    option.correct_matches.length > 0
                  "
                  variant="primary"
                  size="sm"
                  class="mt-1 mr-1 p-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Limpiar Alternativa'
                  "
                  @click="ClearIdsButton(option)"
                >
                  <b-icon icon="x-circle-fill"></b-icon>
                </b-button> -->
                <b-button
                  variant="danger"
                  size="sm"
                  class="mt-1 mr-1 p-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Eliminar Alternativa'
                  "
                  @click="askForDeteleOption(option.id)"
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </div>
            </template>
            <template v-else>
              <!-- <b-button
                v-if="!ids_left_position.includes(option.id)"
                variant="primary"
                size="sm"
                class="mt-1 mr-1 p-1"
                v-b-tooltip.top.noninteractive.v-secondary="
                  'Seleccionar Alternativa'
                "
                @click="addIdOption(option)"
              >
                <b-icon icon="plus-circle-fill"></b-icon>
              </b-button>
              <b-button
                v-else-if="ids_left_position.includes(option.id)"
                variant="primary"
                size="sm"
                class="mt-1 mr-1 p-1"
                v-b-tooltip.top.noninteractive.v-secondary="'Remover Selección'"
                @click="removeIdOption(option)"
              >
                <b-icon icon="dash-circle-fill"></b-icon>
              </b-button> -->
              <b-button
                variant="danger"
                size="sm"
                class="mt-1 mr-1 p-1"
                v-b-tooltip.top.noninteractive.v-secondary="
                  'Eliminar Alternativa'
                "
                @click="askForDeteleOption(option.id)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </template>
          <template v-else>
            <!-- preview_evaluation == true -->
            <template v-if="!display_position_left">
              <div
                class="container-buttons-table-right"
                v-if="!evaluatee_view && !evaluator_view"
              >
                <b-button
                  v-if="tem_ids_left_position.length > 0"
                  variant="primary"
                  size="sm"
                  class="mt-1 p-1 mr-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Agregar términos al siguiente concepto'
                  "
                  @click="SetTemIdsButton(option)"
                >
                  <b-icon icon="arrow-left-circle-fill"></b-icon>
                </b-button>
                <b-button
                  v-if="
                    tem_ids_left_position.length == 0 &&
                    option.tem_correct_matches.length > 0
                  "
                  variant="primary"
                  size="sm"
                  class="mt-1 mr-1 p-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Limpiar Alternativa'
                  "
                  @click="ClearTemIdsButton(option)"
                >
                  <b-icon icon="x-circle-fill"></b-icon>
                </b-button>
              </div>
              <div v-if="evaluatee_view && !finished_test && !evaluator_view">
                <b-button
                  v-if="tem_ids_left_position.length > 0"
                  variant="primary"
                  size="sm"
                  class="mt-1 p-1 mr-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Agregar términos al siguiente concepto'
                  "
                  @click="SetScoreButton(option)"
                >
                  <b-icon icon="arrow-left-circle-fill"></b-icon>
                </b-button>
                <b-button
                  v-if="
                    tem_ids_left_position.length == 0 &&
                    OrdenLeftPosition(option).length > 0
                  "
                  variant="primary"
                  size="sm"
                  class="mt-1 mr-1 p-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Limpiar Alternativa'
                  "
                  @click="ClearScoreButton(option)"
                >
                  <b-icon icon="x-circle-fill"></b-icon>
                </b-button>
              </div>
            </template>
            <template v-else>
              <div
                v-if="preview_evaluation || (evaluator_view && !finished_test)"
              >
                <template
                  v-if="option.how_many_selected > howManySelected(option)"
                >
                  <b-button
                    v-if="!tem_ids_left_position.includes(option.id)"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1 p-1"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      'Seleccionar Alternativa'
                    "
                    @click="addTemIdOption(option)"
                  >
                    <b-icon icon="plus-circle-fill"></b-icon>
                  </b-button>
                </template>
                <template
                  v-else-if="
                    !tem_ids_left_position.includes(option.id) &&
                    option.how_many_selected <= howManySelected(option)
                  "
                >
                  <div
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Máximo de asociaciones alcanzada para ${$htmlToPlainText(
                        option.title
                      )}`
                    "
                  >
                    <b-button
                      v-if="!tem_ids_left_position.includes(option.id)"
                      variant="primary"
                      size="sm"
                      class="mt-1 mr-1 p-1"
                      disabled
                    >
                      <b-icon icon="plus-circle-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
                <b-button
                  v-if="tem_ids_left_position.includes(option.id)"
                  variant="primary"
                  size="sm"
                  class="mt-1 mr-1 p-1"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    'Remover Selección'
                  "
                  @click="removeTemIdOption(option)"
                >
                  <b-icon icon="dash-circle-fill"></b-icon>
                </b-button>
              </div>
            </template>
          </template>
        </td>
        <b-modal
          :id="`descriptor-matching-option-${display_position_left}-${option.id}-${container_instrument_id}`"
          title="Modificar"
          size="lg"
          hide-footer
        >
          <NewRichTextEditor
            :Text="option.title"
            :Object="option"
            :hide_title="true"
            @save="patchTitleOption"
            @close="
              $bvModal.hide(
                `descriptor-matching-option-${display_position_left}-${option.id}-${container_instrument_id}`
              )
            "
          ></NewRichTextEditor>
        </b-modal>
      </b-tr>
    </template>
  </draggable>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { toast } from "@/utils/utils";

export default {
  name: "MatchingQuestion",
  components: {
    draggable,
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    ids_left_position: {
      type: Array,
    },
    tem_ids_left_position: {
      type: Array,
    },
    display_position_left: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      matching_question_options: names.NEW_MATCHING_QUESTION_OPTIONS,
      matching_question_scores: names.NEW_MATCHING_QUESTION_SCORES,
      evaluatee_matching_questions: names.EVALUATEE_MATCHING_QUESTIONS,
      evaluatee_matching_question_units:
        names.EVALUATEE_MATCHING_QUESTION_UNITS,
    }),
    matchingQuestionOptions() {
      return this.matching_question_options
        .filter(
          (x) =>
            x.matching_question == this.Question.id &&
            x.display_to_the_left == this.display_position_left
        )
        .map((x) => {
          return { ...x, tem_correct_matches: [] };
        });
    },
    EvaluateeQuestionScore() {
      if (this.Question.question_type == 4 && this.user_id != null)
        return this.evaluatee_matching_questions.find(
          (x) =>
            x.evaluatee == this.user_id &&
            x.matching_question == this.Question.id
        );
      else return null;
    },
    EvaluateeQuestionUnitScores() {
      if (this.EvaluateeQuestionScore == null) return [];
      else
        return this.evaluatee_matching_question_units.filter(
          (x) => x.evaluatee_matching_question == this.EvaluateeQuestionScore.id
        );
    },
    // optionsMaxScore() {
    //   let score = 0;
    //   this.matchingQuestionOptions.forEach((element) => {
    //     score += parseFloat(element.score);
    //   });
    //   return score;
    // },
    draggableList: {
      get() {
        let list = this.matchingQuestionOptions;
        return list.sort((a, b) => a.order - b.order);
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            this.patchOption(element.id, {
              order: index + 1,
              display_to_the_left: this.display_position_left,
            });
          }
        });
      },
    },
  },
  methods: {
    howManySelected(option) {
      let how_many_selected = 0;
      if (this.evaluatee_view && !this.evaluator_view) {
        const matching_question_scores_ids = this.matching_question_scores
          .filter((x) => x.matching_question_unit_1 == option.id)
          .map((x) => x.id);
        return this.EvaluateeQuestionUnitScores.filter((x) =>
          matching_question_scores_ids.includes(
            x.matching_question_unit_middle_relation
          )
        ).length;
      }
      return how_many_selected;
    },
    // SetIdsButton(option) {
    //   if (this.ids_left_position.length > 0) {
    //     this.patchOption(option.id, {
    //       correct_matches: this.ids_left_position,
    //     });
    //   }
    //   this.$emit("clear_ids_left_position");
    // },
    PatchScore(option) {
      if (
        option.how_many_selected > 0 &&
        option.how_many_selected != null &&
        option.how_many_selected != ""
      ) {
        this.debouncePatch(option);
      }
    },
    debouncePatch(option) {
      if (!isNaN(option.id)) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (
            option.how_many_selected > 0 &&
            option.how_many_selected != null &&
            option.how_many_selected != ""
          )
            this.patchMatchingQuestionOption(option);
        }, 1000);
      }
    },
    patchMatchingQuestionOption(option) {
      this.$store.dispatch(names.PATCH_NEW_MATCHING_QUESTION_OPTION, {
        new_matching_question_option_id: option.id,
        item: {
          how_many_selected: option.how_many_selected,
        },
      });
    },
    // ClearIdsButton(option) {
    //   this.patchOption(option.id, {
    //     correct_matches: [],
    //   });
    // },
    async SetScoreButton(option) {
      // const matching_question_score = this.matching_question_scores.filter(
      //   (x) => x.matching_question_unit_2 == option.id
      // );
      // const matching_question_score_ids = matching_question_score.map(
      //   (x) => x.id
      // );
      const matching_question_score_temp = this.matching_question_scores.filter(
        (x) =>
          x.matching_question_unit_2 == option.id &&
          this.tem_ids_left_position.includes(x.matching_question_unit_1)
      );
      // const matching_question_score_temp_ids = matching_question_score_temp.map(
      //   (x) => x.id
      // );
      // Eliminar el evaluatee_matching_question_unit
      // for await (let element of this.EvaluateeQuestionUnitScores.filter((x) =>
      //   matching_question_score_ids.includes(
      //     x.matching_question_unit_middle_relation
      //   )
      // )) {
      //   if (
      //     !matching_question_score_temp_ids.includes(
      //       element.matching_question_unit_middle_relation
      //     )
      //   )
      //     await this.$store.dispatch(
      //       names.DELETE_EVALUATEE_MATCHING_QUESTION_UNIT,
      //       element.id
      //     );
      // }
      // Agregar el evaluatee_matching_question_unit
      if (this.EvaluateeQuestionScore == null)
        await this.$store
          .dispatch(names.POST_EVALUATEE_MATCHING_QUESTION, {
            evaluatee: this.user_id,
            matching_question: this.Question.id,
            attachments: [],
          })
          .then((evaluatee_matching_question) => {
            for (let element of matching_question_score_temp) {
              if (
                !this.EvaluateeQuestionUnitScores.find(
                  (x) =>
                    x.matching_question_unit_middle_relation == element.id &&
                    evaluatee_matching_question.id ==
                      x.evaluatee_matching_question
                )
              )
                this.$store.dispatch(
                  names.POST_EVALUATEE_MATCHING_QUESTION_UNIT,
                  {
                    evaluatee_matching_question: evaluatee_matching_question.id,
                    matching_question_unit_middle_relation: element.id,
                  }
                );
            }
          });
      else
        for await (let element of matching_question_score_temp) {
          if (
            !this.EvaluateeQuestionUnitScores.find(
              (x) =>
                x.matching_question_unit_middle_relation == element.id &&
                this.EvaluateeQuestionScore.id == x.evaluatee_matching_question
            )
          )
            await this.$store.dispatch(
              names.POST_EVALUATEE_MATCHING_QUESTION_UNIT,
              {
                evaluatee_matching_question: this.EvaluateeQuestionScore.id,
                matching_question_unit_middle_relation: element.id,
              }
            );
        }
      this.$emit("clear_tem_ids_left_position");
    },
    async ClearScoreButton(option) {
      const matching_question_score_ids = this.matching_question_scores
        .filter((x) => x.matching_question_unit_2 == option.id)
        .map((x) => x.id);
      for await (let element of this.EvaluateeQuestionUnitScores.filter((x) =>
        matching_question_score_ids.includes(
          x.matching_question_unit_middle_relation
        )
      )) {
        await this.$store.dispatch(
          names.DELETE_EVALUATEE_MATCHING_QUESTION_UNIT,
          element.id
        );
      }
      this.$emit("clear_tem_ids_left_position");
    },
    SetTemIdsButton(option) {
      if (this.tem_ids_left_position.length > 0) {
        this.tem_ids_left_position.forEach((x) => {
          if (!option.tem_correct_matches.includes(x))
            option.tem_correct_matches.push(x);
        });
        // option.tem_correct_matches = this.tem_ids_left_position;
      }
      this.$emit("clear_tem_ids_left_position");
    },
    ClearTemIdsButton(option) {
      option.tem_correct_matches = [];
      this.$emit("clear_tem_ids_left_position");
    },
    patchOption(option_id, item) {
      const payload = {
        new_matching_question_option_id: option_id,
        item: item,
      };
      this.$store.dispatch(names.PATCH_NEW_MATCHING_QUESTION_OPTION, payload);
    },
    // PatchOptionScore(option) {
    //   if (option.score >= 0 && option.score != null && option.score != "") {
    //     this.debouncePatchOption(option.id, {
    //       score: option.score,
    //     });
    //   }
    // },
    debouncePatchOption(option_id, item) {
      if (!isNaN(option_id)) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.patchOption(option_id, item);
        }, 1000);
      }
    },
    // OrdenLeftPosition(option) {
    //   return [
    //     ...new Set(
    //       this.matching_question_options
    //         .filter(
    //           (x) =>
    //             x.matching_question == this.Question.id &&
    //             option.correct_matches.includes(x.id)
    //         )
    //         .map((x) => x.order)
    //     ),
    //   ]
    //     .sort((a, b) => a - b)
    //     .join(",");
    // },
    OrdenTemLeftPosition(option) {
      return [
        ...new Set(
          this.matching_question_options
            .filter(
              (x) =>
                x.matching_question == this.Question.id &&
                option.tem_correct_matches.includes(x.id)
            )
            .map((x) => String.fromCharCode(96 + x.order).toUpperCase())
        ),
      ]
        .sort((a, b) => a - b)
        .join(",");
    },
    OrdenLeftPosition(option) {
      const matching_question_unit_ids = this.matching_question_scores
        .filter(
          (x) =>
            x.matching_question_unit_2 == option.id &&
            this.EvaluateeQuestionUnitScores.find(
              (u) => u.matching_question_unit_middle_relation == x.id
            )
        )
        .map((x) => x.matching_question_unit_1);
      return [
        ...new Set(
          this.matching_question_options
            .filter(
              (x) =>
                x.matching_question == this.Question.id &&
                matching_question_unit_ids.includes(x.id)
            )
            .map((x) => String.fromCharCode(96 + x.order).toUpperCase())
        ),
      ]
        .sort((a, b) => a - b)
        .join(",");
    },
    addIdOption(option) {
      this.ids_left_position.push(option.id);
    },
    removeIdOption(option) {
      const index = this.ids_left_position.findIndex((x) => x == option.id);
      if (index != -1) this.ids_left_position.splice(index, 1);
    },
    addTemIdOption(option) {
      this.tem_ids_left_position.push(option.id);
    },
    removeTemIdOption(option) {
      const index = this.tem_ids_left_position.findIndex((x) => x == option.id);
      if (index != -1) this.tem_ids_left_position.splice(index, 1);
    },
    showModalQuestion() {
      if (this.allows_crud)
        this.$bvModal.show(
          `edit-title-question-modal-${this.container_instrument_id}`
        );
    },
    patchTitleOption(option, text) {
      this.patchOption(option.id, {
        title: text,
      });
    },
    askForDeteleOption(option_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar la alternativa?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_NEW_MATCHING_QUESTION_OPTION, option_id)
            .then(() => {
              toast("Se eliminó la alternativa ");
            });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.container-buttons-table-right {
  display: flex;
  justify-content: right;
}
.score-input-warning {
  border: 1px solid #dc3545 !important;
  color: red;
}
.input-text {
  width: calc(100%);
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 6.5em;
  max-width: 400px;
  overflow: auto;
  padding: 0.3rem 1.2rem;
  line-height: 1.5;
}
.term-table td {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
}
.input-number {
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
  outline: none;
  max-width: 50px;
}
.input-number:focus {
  border: 1px solid #a1a1a1;
  border-radius: 3px;
}
.input-number-options {
  --bottom-border-color: var(--kl-menu-color);
  max-width: 90%;
  border: none;
  border: 1px solid #c9c9c9;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
.input-number-options:focus {
  outline: none !important;
  border-bottom: 2px solid var(--bottom-border-color);
}
.selected-class {
  background-color: var(--primary-color) !important;
  border: 2px solid rgba(33, 77, 102, 0.5) !important;
}
.selected-class-rich-text {
  border: none;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.input-number {
  --bottom-border-color: var(--kl-menu-color);
  max-width: 71px;
  border: none;
  border: 1px solid #c9c9c9;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
.input-number:focus {
  outline: none !important;
  border-bottom: 2px solid var(--bottom-border-color);
}
@media print {
  .input-text {
    overflow: hidden !important;
    max-height: 100% !important;
  }
}
@media (max-width: 750px) {
}
</style>

