var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{attrs:{"disabled":!_vm.allows_crud,"tag":"b-tbody","handle":".handle"},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},[(_vm.draggableList.length > 0)?_vm._l((_vm.draggableList),function(option){return _c('b-tr',{key:option.id},[(_vm.allows_crud)?_c('b-td',{staticClass:"text-center handle",class:{
          grabbable: _vm.allows_crud,
          'selected-class':
            (_vm.ids_left_position.includes(option.id) && _vm.allows_crud) ||
            (_vm.tem_ids_left_position.includes(option.id) && _vm.preview_evaluation),
        }},[(_vm.allows_crud)?_c('b-icon',{attrs:{"icon":"arrows-expand","scale":"1.2"}}):_vm._e()],1):_vm._e(),(_vm.evaluator_view && _vm.user_id && !_vm.display_position_left)?_c('td',[_vm._v(" "+_vm._s(_vm.OrdenLeftPosition(option))+" ")]):_vm._e(),_c('td',{class:{
          'selected-class':
            (_vm.ids_left_position.includes(option.id) && _vm.allows_crud) ||
            (_vm.tem_ids_left_position.includes(option.id) && _vm.preview_evaluation),
        }},[(_vm.display_position_left)?[_vm._v(" "+_vm._s(String.fromCharCode(96 + option.order).toUpperCase())+" ")]:[(!_vm.evaluatee_view)?_c('div',{staticClass:"noprint"},[(
                !_vm.preview_evaluation ||
                (_vm.evaluator_view && !_vm.display_position_left)
              )?[_vm._v(" "+_vm._s(option.order)+" ")]:(_vm.preview_evaluation)?[_vm._v(" "+_vm._s(_vm.OrdenTemLeftPosition(option))+" ")]:_vm._e()],2):(_vm.evaluatee_view)?_c('div',[_vm._v(" "+_vm._s(_vm.OrdenLeftPosition(option))+" ")]):_vm._e()]],2),_c('td',{class:{
          'selected-class':
            (_vm.ids_left_position.includes(option.id) && _vm.allows_crud) ||
            (_vm.tem_ids_left_position.includes(option.id) && _vm.preview_evaluation),
        }},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(`${_vm.allows_crud ? 'Editar' : ''}`),expression:"`${allows_crud ? 'Editar' : ''}`",modifiers:{"v-secondary":true}}],staticClass:"input-text rich-text-content",class:{
            'selected-class-rich-text':
              (_vm.ids_left_position.includes(option.id) && _vm.allows_crud) ||
              (_vm.tem_ids_left_position.includes(option.id) &&
                _vm.preview_evaluation),
          },domProps:{"innerHTML":_vm._s(option.title)},on:{"click":() => {
              if (_vm.allows_crud)
                _vm.$bvModal.show(
                  `descriptor-matching-option-${_vm.display_position_left}-${option.id}-${_vm.container_instrument_id}`
                );
            }}})]),(_vm.allows_crud && !_vm.preview_evaluation && _vm.display_position_left)?_c('td',[_c('b-form-input',{staticClass:"ml-2 mr-2 input-number-options",attrs:{"type":"number","min":"1","size":"sm"},on:{"input":function($event){return _vm.PatchScore(option)}},model:{value:(option.how_many_selected),callback:function ($$v) {_vm.$set(option, "how_many_selected", $$v)},expression:"option.how_many_selected"}})],1):_vm._e(),(
          _vm.allows_crud ||
          (_vm.preview_evaluation && !_vm.evaluatee_view && !_vm.evaluator_view) ||
          (_vm.evaluatee_view && !_vm.finished_test && !_vm.evaluator_view)
        )?_c('td',{staticClass:"noprint"},[(!_vm.preview_evaluation)?[(!_vm.display_position_left)?[_c('div',{staticClass:"container-buttons-table-right"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Eliminar Alternativa'
                ),expression:"\n                  'Eliminar Alternativa'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 mr-1 p-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.askForDeteleOption(option.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1)]:[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                'Eliminar Alternativa'
              ),expression:"\n                'Eliminar Alternativa'\n              ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 mr-1 p-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.askForDeteleOption(option.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)]]:[(!_vm.display_position_left)?[(!_vm.evaluatee_view && !_vm.evaluator_view)?_c('div',{staticClass:"container-buttons-table-right"},[(_vm.tem_ids_left_position.length > 0)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Agregar términos al siguiente concepto'
                ),expression:"\n                  'Agregar términos al siguiente concepto'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 p-1 mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.SetTemIdsButton(option)}}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill"}})],1):_vm._e(),(
                  _vm.tem_ids_left_position.length == 0 &&
                  option.tem_correct_matches.length > 0
                )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Limpiar Alternativa'
                ),expression:"\n                  'Limpiar Alternativa'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 mr-1 p-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.ClearTemIdsButton(option)}}},[_c('b-icon',{attrs:{"icon":"x-circle-fill"}})],1):_vm._e()],1):_vm._e(),(_vm.evaluatee_view && !_vm.finished_test && !_vm.evaluator_view)?_c('div',[(_vm.tem_ids_left_position.length > 0)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Agregar términos al siguiente concepto'
                ),expression:"\n                  'Agregar términos al siguiente concepto'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 p-1 mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.SetScoreButton(option)}}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill"}})],1):_vm._e(),(
                  _vm.tem_ids_left_position.length == 0 &&
                  _vm.OrdenLeftPosition(option).length > 0
                )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Limpiar Alternativa'
                ),expression:"\n                  'Limpiar Alternativa'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 mr-1 p-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.ClearScoreButton(option)}}},[_c('b-icon',{attrs:{"icon":"x-circle-fill"}})],1):_vm._e()],1):_vm._e()]:[(_vm.preview_evaluation || (_vm.evaluator_view && !_vm.finished_test))?_c('div',[(option.how_many_selected > _vm.howManySelected(option))?[(!_vm.tem_ids_left_position.includes(option.id))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    'Seleccionar Alternativa'
                  ),expression:"\n                    'Seleccionar Alternativa'\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 mr-1 p-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.addTemIdOption(option)}}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill"}})],1):_vm._e()]:(
                  !_vm.tem_ids_left_position.includes(option.id) &&
                  option.how_many_selected <= _vm.howManySelected(option)
                )?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Máximo de asociaciones alcanzada para ${_vm.$htmlToPlainText(
                      option.title
                    )}`
                  ),expression:"\n                    `Máximo de asociaciones alcanzada para ${$htmlToPlainText(\n                      option.title\n                    )}`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}]},[(!_vm.tem_ids_left_position.includes(option.id))?_c('b-button',{staticClass:"mt-1 mr-1 p-1",attrs:{"variant":"primary","size":"sm","disabled":""}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill"}})],1):_vm._e()],1)]:_vm._e(),(_vm.tem_ids_left_position.includes(option.id))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Remover Selección'
                ),expression:"\n                  'Remover Selección'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"mt-1 mr-1 p-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.removeTemIdOption(option)}}},[_c('b-icon',{attrs:{"icon":"dash-circle-fill"}})],1):_vm._e()],2):_vm._e()]]],2):_vm._e(),_c('b-modal',{attrs:{"id":`descriptor-matching-option-${_vm.display_position_left}-${option.id}-${_vm.container_instrument_id}`,"title":"Modificar","size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Text":option.title,"Object":option,"hide_title":true},on:{"save":_vm.patchTitleOption,"close":function($event){return _vm.$bvModal.hide(
              `descriptor-matching-option-${_vm.display_position_left}-${option.id}-${_vm.container_instrument_id}`
            )}}})],1)],1)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }